import React, { useState } from 'react'
import classes from './ProductDetail.module.css'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import DetailSlider from './DetailSlider';

import { FaPhone, FaPhoneAlt } from 'react-icons/fa';
import { ImMobile } from 'react-icons/im';
import MainHighlight from './MainHighlight';
import { DiscountFunction } from '../../DiscountFunction/DiscountFunction';
import { ImAppleinc } from "react-icons/im";
import { TiVendorAndroid } from "react-icons/ti";

const ProductDetail = (props) => {
  let data = props?.productData
  let [orderThroughClicked, setOrderThroughClicked] = useState(false);

  const orderCall = () => {
    // On click, navigate to call this number
    window.location.href = "tel:18003134207";
  };

  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  }
  
  const orderThroughApp = () => {
    const os = getMobileOperatingSystem();
    if (os == "Android" || os == "iOS") {
      window.open(
        "/app/dynamic-install",
        "_blank" // <- This is what makes it open in a new window.
      );
    } else {
      setOrderThroughClicked(true);
    }
  };

    const downloadApk = () => {
      window.open(
        "https://play.google.com/store/apps/details?id=com.coremyapp",
        "_blank" // <- This is what makes it open in a new window.
      );
    };

    const downloadIos = () => {
      window.open(
        "https://apps.apple.com/in/app/corelens/id6621260366",
        "_blank" // <- This is what makes it open in a new window.
      );
    };



  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header className={classes.head_modal} closeButton>
        Product Detail
      </Modal.Header>

      <Modal.Body className={classes.bdy}>
        <div className={classes.main_div}>
          <DetailSlider img={data?.img} />
          <div>
            <h1 className={classes.head}>{data?.name}</h1>
            <p className={classes.description}>{data?.description}</p>
            <p className={classes.para}>Main Highlights</p>
            <div className={classes.main_div2}>
              <MainHighlight data={data?.mainhighlight} />
            </div>
            <p className={`${classes.para} ${classes.p2}`}>Technical highlights</p>
            {data?.technicalHightlight?.length > 0 ? (
              data?.technicalHightlight?.map((item) => (
                <div className={classes.ul}>
                  <div className={classes.inn_ul}>
                    <p>{item.title}</p> <span>{item.description}</span>
                  </div>
                </div>
              ))
            ) : (
              <div>
                {/* <ul className={classes.ul}> */}
                <div className={classes.ul}>
                  <div className={classes.inn_ul}>
                    <p>Brand</p> <span>Corelens</span>
                  </div>
                  <div className={classes.inn_ul}>
                    <p>Model Name</p> <span>V45</span>
                  </div>
                  <div className={classes.inn_ul}>
                    <p>Connectivity Technology</p> <span>Wired</span>
                  </div>
                  <div className={classes.inn_ul}>
                    <p>Special Feature</p> <span>PTZ Technology</span>
                  </div>
                  <div className={classes.inn_ul}>
                    <p>Indoor/Outdoor Usage</p> <span>Outdoor</span>
                  </div>
                  <div className={classes.inn_ul}>
                    <p>Compatible Devices</p> <span>Laptop, Smart Phone</span>
                  </div>
                  <div className={classes.inn_ul}>
                    <p>Power Source</p> <span>Corded Electric</span>
                  </div>
                  <div className={classes.inn_ul}>
                    <p>Connectivity Protocol</p> <span>HomePlug</span>
                  </div>
                  <div className={classes.inn_ul}>
                    <p>Controller Type</p> <span>Android</span>
                  </div>
                </div>
              </div>
            )}
            <div style={{ marginTop: "20px" }}>
              <p className={classes.para2}>
                ₹{data?.saleAmount}
                {data?.actualAmount && (
                  <p className={classes.p9}>
                    MRP{" "}
                    <span
                      style={{
                        textDecoration: "line-through",
                      }}
                    >
                      ₹{data?.actualAmount}
                    </span>
                  </p>
                )}
                {data?.discount && <span style={{ color: "#FF774F" }}>({data?.discount} Off)</span>}
              </p>
              <h1 className={classes.head2}>Inclusive all Taxes</h1>
            </div>
            {/* If else logic to show the app download links when user tries to order through app */}
            {!orderThroughClicked ? (
              <div className={classes.btm_btn_div}>
                <button className={classes.buy_btn} onClick={orderCall} style={{ background: "black" }}>
                  Order Through Call <FaPhoneAlt />
                </button>
                <button className={classes.buy_btn} onClick={orderThroughApp}>
                  Order Through App <ImMobile style={{ fontSize: "18px" }} />
                </button>
              </div>
            ) : (
              <div className={classes.btm_btn_div}>
                <button className={classes.buy_btn} onClick={orderCall} style={{ background: "black" }}>
                  Order Through Call <FaPhoneAlt />
                </button>
                <div className={classes.buy_btn_container}>
                  <button className={classes.buy_btn} onClick={downloadIos}>
                    <ImAppleinc style={{ fontSize: "30px" }} />
                    <span>
                      <span>Download on </span>App Store
                    </span>
                  </button>
                  <button className={classes.buy_btn} onClick={downloadApk}>
                    {" "}
                    <TiVendorAndroid style={{ fontSize: "40px" }} />
                    <span>
                      <span>Get It On </span>Google Play
                    </span>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ProductDetail