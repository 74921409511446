import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToSection() {
  const { hash } = useLocation();

  useEffect(() => {
    if (!hash) return;

    const scrollToTarget = () => {
      const element = document.querySelector(hash);
      if (element) {
        console.log(element.offsetTop);
        element.scrollIntoView({ behavior: "smooth" });
        // window.scrollTo({
        //   top: element.offsetTop + 2000, // Adjust for header height
        //   behavior: "smooth",
        // });

      }
    };

    // Try scrolling immediately
    setTimeout(() => {
      scrollToTarget();
    }, 500);

    // Observe changes in the DOM to scroll when the section is loaded
    // const observer = new MutationObserver((mutations) => {
    //   console.log(mutations);
    //   scrollToTarget();
    // });

    // observer.observe(document.body, { childList: true, subtree: true });

    // return () => observer.disconnect();
  }, [hash]);

  return null;
}
