import React, { useEffect, useRef, useState } from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import { Pagination, Navigation } from 'swiper/modules';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import Product from './Product';
import Heading from '../Heading/Heading';
import classes from "./Product.module.css"
import ProductDetail from './ProductDetails/ProductDetail';
import AOS from 'aos'
import 'aos/dist/aos.css';
import unalteredProducts from "./products.json";

// Imports images dynamically from their file names
const products = setImagePaths(unalteredProducts);

const ProductSlider = ({ searchTerm }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [showAllProducts, setShowAllProducts] = useState(false);

    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: true, // Ensure the animation triggers every time you scroll
        });

        // Reinitialize AOS when component updates, if necessary
        AOS.refresh();
    }, []);

    const productsRef = useRef(null);
    useEffect(() => {
        setFilteredProducts(products);
    }, []);

    useEffect(() => {
        if (searchTerm) {
            const filtered = products.filter(product => product.name.toLowerCase().includes(searchTerm.toLowerCase()));
            if (filtered.length > 0) {
                setFilteredProducts(filtered);
            } else {
                // Show all products when no matches
                setFilteredProducts(products);
            }
            // Scroll to the product section when search term changes
            productsRef.current.scrollIntoView({ behavior: 'smooth' });
        } else {
            setFilteredProducts(products);
        }
    }, [searchTerm]);


    // useEffect(() => {
    //     const observer = new IntersectionObserver((entries) => {
    //         entries.forEach(entry => {
    //             if (entry.isIntersecting) {
    //                 entry.target.classList.add('fadeInUp');
    //                 entry.target.classList.remove('hidden');
    //             }
    //         });
    //     }, { threshold: 0.1 });

    //     const items = document.querySelectorAll('.productItem');
    //     items.forEach(item => {
    //         item.classList.add('hidden');
    //         observer.observe(item);
    //     });

    //     return () => {
    //         items.forEach(item => {
    //             observer.unobserve(item);
    //         });
    //     };
    // }, [filteredProducts]);


    const toggleProductsView = () => {
        setShowAllProducts(!showAllProducts);
        if (!showAllProducts) {
            setTimeout(() => {
                productsRef.current.scrollIntoView({ behavior: 'smooth' });
            }, 500); // No delay needed for smooth scrolling to the top
        } else {
            productsRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };


    const openModal = (product) => {
        setSelectedProduct(product);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setSelectedProduct(null);
        setIsModalOpen(false);
    };


    return (
      <div id="product" ref={productsRef} className={classes.products_container}>
        <Heading
          heading="Our Products"
          highlight="Our Latest"
          subtitle="Find the best product"
          cls={classes.div_head}
        />

        <div className={classes.ProductSlider}>
          {filteredProducts.length > 0 && (
            showAllProducts ? (
              <div className={`${classes.allProducts} ${showAllProducts ? classes.showAllProducts : ""}`}>
                <div className={classes.productList}>
                  {filteredProducts.map((product, index) => (
                    <div key={product.id} className={classes.productItem}>
                      <Product
                        cls={classes[product.cls]}
                        index={index + 1}
                        data={product}
                        onClick={() => openModal(product)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <Swiper
                pagination={{ type: "progressbar" }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                loop={true}
                style={{ padding: "20px" }}
                autoplay={{ delay: 5000, disableOnInteraction: true }}
                className={"home_slider home_slider1 prmodal"}
                slidesPerView={1}
                breakpoints={{
                  360: { slidesPerView: 1 },
                  690: { slidesPerView: 2 },
                  1000: { slidesPerView: 3 },
                  1200: { slidesPerView: 4 },
                }}
                spaceBetween={30}
              >
                {filteredProducts.map((item, index) => (
                  <SwiperSlide key={index}>
                    <Product
                      onClick={() => openModal(item)}
                      data={item}
                      cls={classes[item.cls]}
                      index={index}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            )
          )}
          {filteredProducts.length > 0 && (
            <button className={classes.view} onClick={toggleProductsView}>
              {showAllProducts ? "View Less" : "View More"}
            </button>
          )}

          {isModalOpen && <ProductDetail show={isModalOpen} onHide={closeModal} productData={selectedProduct} />}
        </div>
      </div>
    );
}

export default ProductSlider

function setImagePaths(products) {
  const updatedProducts = [];
  products.forEach((product) => {
    const imagesArray = [];
    product.img.forEach((imageName) => {
      try {
        const img = require("../../Assets/" + imageName + ".png");
        imagesArray.push(img);
      } catch (e) {
        console.error("Cannot find image: " + imageName);
      }
    });

    const updatedHighlights = [];
    product.mainhighlight.forEach((highlight) => {
      try {
        const img = require("../../Assets/" + highlight.img + ".png");
        updatedHighlights.push({ ...highlight, img });
      } catch (e) {
        console.error("Cannot find image: " + highlight.img);
      }
    });
    updatedProducts.push({ ...product, img: imagesArray, mainhighlight: updatedHighlights });
  });

  return updatedProducts;
};