import React, { useEffect, useState } from "react";

const AppInstall = () => {
  /**
   * Determine the mobile operating system.
   * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
   *
   * @returns {String}
   */
  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  }

  useEffect(() => {
    const os = getMobileOperatingSystem();
    let redirectLink = "/#btm-section";
    if (os == "Android") {
      redirectLink = "https://play.google.com/store/apps/details?id=com.coremyapp&hl=en_IN";
    } else if (os == "iOS") {
      redirectLink = "https://apps.apple.com/in/app/corelens/id6621260366";
    }
    window.location = redirectLink;
  });

  return <React.Fragment>
    <div>
      Redirecting to app store...
    </div>
  </React.Fragment>;
};

export default AppInstall;
